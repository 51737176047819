/* eslint-disable import/no-default-export */
/* eslint-disable object-curly-newline */
/* eslint-disable react/destructuring-assignment */

'use strict';

import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import autobind from 'autobind-decorator';
import CodeMirror from 'codemirror/lib/codemirror';

import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/display/placeholder';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/markdown/markdown';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/swift/swift';
import 'codemirror/mode/php/php';
import 'codemirror/mode/ruby/ruby';
import 'codemirror/mode/smarty/smarty';
import 'codemirror/mode/slim/slim';

import { MODE } from './CodeEditor.helper';

export const CODE_EDITOR_MODES = {
  CSS: 'css',
  UXPIN_EXPRESSIONS: MODE,
  HTML_MIXED: 'htmlmixed',
  JAVA: 'clike',
  JAVASCRIPT: 'javascript',
  MARKDOWN: 'markdown',
  OBJECTIVE_C: 'clike',
  PHP: 'php',
  RUBY: 'ruby',
  SLIM: 'slim',
  SMARTY: 'smarty',
  SWIFT: 'swift',
};

export const CODE_EDITOR_THEMES = {
  DEFAULT: 'default',
  DISABLED: 'uxpin-disabled',
  READONLY: 'uxpin-readonly',
  UXPIN: 'uxpin',
};

const HEIGHT_LIMIT = 130;
const FOCUS_DELAY = 100;

export default class CodeEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.codeMirror = null;
  }

  @autobind
  initializeCodeMirror() {
    const {
      autoRefresh,
      disabled,
      height,
      mode,
      lineNumbers,
      lineWrapping,
      placeholder,
      preventFocus,
      shouldSetHeight,
      value,
    } = this.props;
    const { UXPIN, DISABLED } = CODE_EDITOR_THEMES;
    const theme = disabled ? DISABLED : UXPIN;

    const codeMirror = CodeMirror.fromTextArea(this.textArea, {
      autoRefresh,
      mode,
      placeholder,
      theme,
      lineNumbers,
      lineWrapping,
      value,
    });

    this.codeMirror = codeMirror;

    codeMirror.setValue(value);
    if (shouldSetHeight) {
      codeMirror.setSize(null, height);
    }
    if (this.props.keyMap) {
      codeMirror.addKeyMap(this.props.keyMap);
    }
    codeMirror.on('change', this.onChange);
    codeMirror.on('blur', this.onBlur);
    codeMirror.on('focus', this.onFocus);
    this.props.setInstance(codeMirror);

    if (!value && !preventFocus) {
      setTimeout(() => {
        this.codeMirror.focus();
      }, FOCUS_DELAY);
    }
  }

  @autobind
  onBlur(codeMirror) {
    this.props.onBlur(codeMirror.getValue(), codeMirror);
  }

  @autobind
  onFocus(codeMirror) {
    this.props.onFocus(codeMirror.getValue(), codeMirror);
  }

  @autobind
  onChange(codeMirror) {
    this.props.onChange(codeMirror.getValue(), codeMirror);
  }

  destroyCodeMirror() {
    if (this.codeMirror) {
      this.codeMirror.toTextArea();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { mode, value } = this.props;

    if (nextProps.mode && nextProps.mode !== mode) {
      this.codeMirror.setOption('mode', nextProps.mode);
    }

    if (this.codeMirror && !this.codeMirror.hasFocus() && (nextProps.value !== value || nextProps.forceUpdateValue)) {
      this.codeMirror.setValue(nextProps.value);
    }
  }

  componentWillUnmount() {
    this.destroyCodeMirror();
  }

  @autobind
  setTextArea(ref) {
    this.textArea = ref;

    if (ref !== null) {
      this.initializeCodeMirror();
    }
  }

  render() {
    return <textarea ref={this.setTextArea} />;
  }
}

CodeEditor.propTypes = {
  autoRefresh: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  disabled: PropTypes.bool.isRequired,
  forceUpdateValue: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  height: PropTypes.number,
  keyMap: PropTypes.object,
  lineNumbers: PropTypes.bool,
  lineWrapping: PropTypes.bool,
  mode: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  preventFocus: PropTypes.bool,
  setInstance: PropTypes.func,
  shouldSetHeight: PropTypes.bool,
  value: PropTypes.string,
};

CodeEditor.defaultProps = {
  autoRefresh: false,
  forceUpdateValue: false,
  height: HEIGHT_LIMIT,
  keyMap: null,
  lineNumbers: true,
  lineWrapping: true,
  mode: CODE_EDITOR_MODES.CSS,
  onFocus: noop,
  onBlur: noop,
  onChange: noop,
  placeholder: '',
  preventFocus: false,
  setInstance: noop,
  shouldSetHeight: true,
  value: '',
};

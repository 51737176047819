/* eslint-disable no-useless-escape */

import CodeMirror from 'codemirror/lib/codemirror';
import 'codemirror/addon/mode/simple';

export const tokens = {
  ALL: 'all',
  ELEMENT: 'element',
  FUNCTION: 'function',
  FUNCTION_UNFINISHED: 'function_unfinished',
  NUMBER: 'number',
  OPERATOR: 'operator',
  PARENTHESES: 'parentheses',
  STRING: 'string',
  STRING_UNCLOSED: 'string_unclosed',
  VARIABLE: 'variable',
};

export const INVALID_TOKENS = [tokens.FUNCTION_UNFINISHED, tokens.STRING_UNCLOSED];

export const INVALID_TOKEN_TO_ERROR_MAP = {
  [tokens.FUNCTION_UNFINISHED]: 'Expression excpected',
  [tokens.STRING_UNCLOSED]: 'Unclosed string literal',
};

export const MODE = 'uxpinExpressions';

CodeMirror.defineSimpleMode(MODE, {
  start: [
    { regex: /"([^\\"]|\\")*"/, token: tokens.STRING },
    { regex: /"(?:[^\\]|\\.)*?(?:"|$)/, token: tokens.STRING_UNCLOSED },
    { regex: /true|false/, token: 'atom' },
    { regex: /0x[a-f\d]+|[-+]?(?:\.\d+|\d+\.?\d*)(?:e[-+]?\d+)?/i, token: tokens.NUMBER },
    { regex: /[-+\/*=<>!]+/, token: tokens.OPERATOR },
    { regex: /(\(|\))/, token: tokens.PARENTHESES },
    { regex: /[\{\[\(]/, indent: true },
    { regex: /[\}\]\)]/, dedent: true },
    { regex: /\$[\w$]*/, token: tokens.VARIABLE },
    { regex: /'(?:[^\\]|\\.)*?(?:'|$)/, token: tokens.ELEMENT },
    { regex: /[a-z]+(?=\(.*?\))/i, token: tokens.FUNCTION },
    { regex: /[a-z]+\(('(.*?)'|(\$\w)|[\w," ]*)+/i, token: tokens.FUNCTION_UNFINISHED },
    { regex: /[a-z][\w$]*/i, token: tokens.ALL },
  ],
  // The multi-line comment state.
  comment: [],
});

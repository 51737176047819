import { connect } from 'react-redux';
import { ModalboxShare } from '@uxpin/shared-components';
import * as shareActions from '../../controllers/actions/share.actions';
import * as modal from '../../controllers/actions/modal.actions';
import * as slack from '../../controllers/actions/slack.actions';
import { modalsIds } from '../../constants/modal.constants';
import * as upgradeModal from '../../controllers/actions/upgradeModal.actions';
import { openFeatureUpgrade } from '../../controllers/actions/pricing.actions';

import { setDocumentPreviewPassword } from '../../controllers/actions/document.actions';
import { request } from '../../utils/request';
import * as codeEditor from '../components/CodeEditor/CodeEditor.react';

export const mapStateToProps = (state) => {
  const {
    hasTypekitFontsInProject,
    isDocumentSharingByEmail,
    isEmailError,
    pages,
    pendingExports,
    phonePrefixes,
  } = state.share;
  const {
    channels, slackState, selectedChannel, isMessageSending, isMessageSendingError,
  } = state.slack;
  const { data: currentUserData } = state.currentUser;
  const { data: modalData, openedModalsIds } = state.modal;
  const { features } = state.features;
  const { permissions: { has_access_to_editor: hasAccessToEditor } } = state.permissions;
  const { accountSettings } = state.accountSettings;


  return {
    hasTypekitFontsInProject,
    hasAccessToEditor: hasAccessToEditor && hasAccessToEditor.selected !== 'no',
    phonePrefixes,
    isDocumentSharingByEmail,
    isEmailError,
    pages,
    channels,
    features,
    slackState,
    selectedChannel,
    isMessageSending,
    isMessageSendingError,
    pendingExports,
    loggedUserEmail: currentUserData.email || '',
    isOpen: openedModalsIds[modalsIds.MODALBOX_SHARE] || false,
    document: modalData[modalsIds.MODALBOX_SHARE] || {},
    accountSettings,
    codeEditor: codeEditor,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_SHARE));
  },

  sendMessageToChannel: (channel, message) => {
    dispatch(slack.sendMessageToChannel(channel, message));
  },

  shareDocumentByEmail: (idDocument, documentHash, recipients, message, reCaptchaValue) => {
    dispatch(shareActions.shareDocumentByEmail(idDocument, documentHash, recipients, message, reCaptchaValue));
  },

  openUpgradeModal: () => {
    dispatch(upgradeModal.openUpgradeModal());
  },

  setDocumentPreviewPassword: (idDocument, password) => {
    dispatch(setDocumentPreviewPassword(idDocument, password));
  },

  fetchShareData: (idDocument) => {
    dispatch(shareActions.fetchShareData(idDocument));
  },

  exportDocument: (idDocument, data) => {
    dispatch(shareActions.exportDocument(idDocument, data));
  },

  fetchTypekitFontsInProjectInfo: (idProject) => {
    dispatch(shareActions.fetchTypekitFontsInProjectInfo(idProject));
  },

  hasLocalFontsInProject: (idProject) => request.get(`/fonts/local/project_fonts?id=${idProject}`).catch(() => {}),

  openFeatureUpgradeModal: (feature) => {
    dispatch(openFeatureUpgrade(feature));
  },
});

export const ShareModal = connect(mapStateToProps, mapDispatchToProps)(ModalboxShare);
